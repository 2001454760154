<template>
  <div class="unlock">
    <v-header useHolder useSlot>
      <div class="header-tab">
        <div @click="$router.push('/collection')" class="header-tab-item"
             :class="{active: $route.path === '/collection'}">我的收藏
        </div>
        <div @click="$router.push('/unlock')" class="header-tab-item" :class="{active: $route.path === '/unlock'}">
          我的解锁
        </div>
      </div>
    </v-header>
    <van-tabs swipeable animated sticky :active="currTab">
      <van-tab name="plan" title="收藏的方案">
        <van-pull-refresh v-model="plan.isRefresh" @refresh="loadPlanPageData({current: 1, isRefresh: true})">
          <van-list v-model="plan.loading" loading-text="灵感加载中"
                    :finished-text="plan.pageData.records.length?'灵感到底了~':''"
                    @load="loadPlanPageData()"
                    :finished="plan.pageData.records.length >= plan.pageData.total">
            <div class="case-container">
              <template>
                <template v-if="plan.isRefresh">
                  <div class="skeleton-block" style="width: 100%;height: 300px;margin-bottom: 16px" v-for="i in 3"
                       :key="i"></div>
                </template>
                <div v-else-if="!plan.pageData.records.length"
                     style="width: calc(100vw - 56px);padding-top: 200px;text-align: center">
                  <img src="https://cdn.001ppt.cn/pc2/static/imgs/svg/collect-empty.svg" alt="" style="width: 200px">
                  <div style="padding-top: 16px;font-size: 14px;color: #999">噢！这可能有点儿空 (⊙v⊙)</div>
                  <div class="go-unlock-btn" @click="$router.push('/home')">去收藏</div>
                </div>
              </template>

              <plan-card style="margin-bottom: 16px" @handle-click="$router.push(`/caseDetail/${$event.plan.id}`)"
                         v-for="(item,index) in plan.pageData.records" :detail="item" :key="index"></plan-card>
            </div>
          </van-list>
        </van-pull-refresh>
      </van-tab>
      <van-tab name="planList" title="收藏的合集">
        <van-pull-refresh v-model="planList.isRefresh" @refresh="loadPlanListPageData({current: 1, isRefresh: true})">
          <van-list v-model="planList.loading" loading-text="灵感加载中"
                    :finished-text="planList.pageData.records.length?'灵感到底了~':''"
                    @load="loadPlanListPageData()"
                    :finished="planList.pageData.records.length >= planList.pageData.total">
            <div class="case-container">
              <template>
                <template v-if="planList.isRefresh">
                  <div class="skeleton-block" style="width: 100%;height: 300px;margin-bottom: 16px" v-for="i in 3"
                       :key="i"></div>
                </template>
                <div v-else-if="!planList.pageData.records.length"
                     style="width: calc(100vw - 56px);padding-top: 200px;text-align: center">
                  <img src="https://cdn.001ppt.cn/pc2/static/imgs/svg/collect-empty.svg" alt="" style="width: 200px">
                  <div style="padding-top: 16px;font-size: 14px;color: #999">噢！这可能有点儿空 (⊙v⊙)</div>
                  <div class="go-unlock-btn" @click="$router.push('/home?tab=collection')">去收藏</div>
                </div>
              </template>

              <v-case group @handle-click="$router.push(`/casePlanDetail/${$event.id}`)"
                      v-for="item in planList.pageData.records" :detail="item"></v-case>
            </div>
          </van-list>
        </van-pull-refresh>
      </van-tab>
    </van-tabs>

  </div>
</template>

<script>
import vCase from '@/components/case.vue'
import {planPayV2Api} from "../../api/v2/planPayV2Api";
import {debounce} from "../../config/decorators/debounce";
import {sleep} from "../../config/util";
import {syncronized} from "../../config/decorators/syncronized";
import {user} from "@/api"
import {planCollectApiV2} from "../../api/v2/planCollectV2Api";
import {planListV2Api} from "../../api/v2/planListV2Api";
import PlanCard from "../../components/planCard";

export default {
  components: {PlanCard, vCase},

  async activated() {
    if ('refresh' in this.$route.query) {
      this.plan.isRefresh = true
      await sleep(200)
      await this.loadPlanPageData({current: 1, isRefresh: true})
      await this.$router.replace('/collection')
    }
  },


  mounted() {
    this.currTab = this.$route.query.currTab === 'planList' ? 'planList' : 'plan'
  },

  data() {
    return {
      currTab: 'plan',
      plan: {
        isRefresh: false,
        loading: false,
        pageData: {
          current: 0,
          size: 10,
          records: [],
          total: 100
        }
      },
      planList: {
        isRefresh: false,
        loading: false,
        pageData: {
          current: 0,
          size: 10,
          records: [],
          total: 100
        }
      }
    }
  },


  methods: {

    loadPlanPageData: syncronized(async function (e = {}) {
      try {
        if (!this.plan.pageData.records.length) {
          this.plan.isRefresh = true
        }
        if (this.plan.pageData.records.length >= this.plan.pageData.total) {
          return
        }
        const current = e.current || this.plan.pageData.current + 1
        const size = e.size || e.pageSize || this.plan.pageData.size
        const res = await planCollectApiV2.findMy({current, size})

        this.plan.pageData.current = res.current
        this.plan.pageData.size = res.size || res.pageSize
        this.plan.pageData.pageSize = res.size || res.pageSize
        this.plan.pageData.total = res.total
        if (e.isRefresh) {
          this.plan.pageData.records = res.records
        } else {
          this.plan.pageData.records.push(...res.records)
        }
      } finally {
        this.plan.loading = false
        this.plan.isRefresh = false
      }
    }),


    loadPlanListPageData: syncronized(async function (e = {}) {
      try {
        if (!this.planList.pageData.records.length) {
          this.planList.isRefresh = true
        }
        if (this.planList.pageData.records.length >= this.planList.pageData.total) {
          return
        }
        const current = e.current || this.planList.pageData.current + 1
        const size = e.size || e.pageSize || this.planList.pageData.size

        const res = await planListV2Api.findMyCollect({current, size, pageSize: size})
        this.planList.pageData.current = res.current
        this.planList.pageData.size = res.size || res.pageSize
        this.planList.pageData.pageSize = res.size || res.pageSize
        this.planList.pageData.total = res.total
        if (e.isRefresh) {
          this.planList.pageData.records = res.records
        } else {
          this.planList.pageData.records.push(...res.records)
        }
      } finally {
        this.planList.loading = false
        this.planList.isRefresh = false
      }
    })
  }

}
</script>

<style scoped>
.unlock {
  background: #f4f4f4;
  min-height: 100vh;
}

.case-container {
  padding: 16px 28px 0 28px;
}

.go-unlock-btn {
  width: 140px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  background: #fff;
  margin: 32px auto;
  cursor: pointer;
}

.header-tab {
  display: flex;
  align-items: center;
  justify-content: center;

  .header-tab-item {
    padding: 2px 12px;
  }

  .header-tab-item.active {
    background: #151d36;
    color: #fff;
    border-radius: 100px;
  }
}
</style>
